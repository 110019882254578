(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sportinfo/bigstat-banner/assets/javascripts/bigstat-banner.js') >= 0) return;  svs.modules.push('/components/sportinfo/bigstat-banner/assets/javascripts/bigstat-banner.js');
"use strict";

const {
  useState,
  useCallback
} = React;
const logger = svs.core.log.getLogger('sport:sportinfo-bigstat-banner');
const {
  matchState: {
    SportinfoMatchState
  }
} = svs.components.sportinfo.common;
const {
  helpers
} = svs.components.sport.sportEventCommon;
const {
  SportTypeToString
} = svs.components.sport.sportTypes;
const {
  MatchHeader,
  MatchProgress
} = svs.components.sportInfo.bigStatBanner;
const {
  useSocket
} = svs.components.lbUtils.socket;
const productIds = svs.utils.products.productIds;
const {
  ReactIcon: Icon,
  ReactIconButton: IconButton
} = svs.ui;
const LiveDataSubscriber = _ref => {
  let {
    path,
    onUpdate
  } = _ref;
  useSocket(path, onUpdate);
  return null;
};
const BigStatBanner = _ref2 => {
  let {
    homeTeam,
    awayTeam,
    leagueName,
    odds,
    matchState,
    onCloseClick,
    sportType,
    startTime,
    results,
    matchId,
    productId,
    eventNumber,
    sportEventStatus,
    statusTime,
    className
  } = _ref2;
  const [liveResults, setLiveResults] = useState(undefined);
  const [liveStatusTime, setLiveStatusTime] = useState(undefined);
  const [liveSportEventStatus, setLiveSportEventStatus] = useState(undefined);
  const handleSocketUpdate = useCallback(result => {
    const {
      error,
      sportEvent
    } = result;
    if (!error) {
      const currentResult = helpers.getCurrentResult(sportEvent.results);
      setLiveResults(currentResult || {
        home: '0',
        away: '0'
      });
      setLiveStatusTime(sportEvent.statusTime);
      setLiveSportEventStatus(sportEvent.status);
    } else {
      logger.error("Error in socket subscription for sportevent ".concat(matchId, ": ").concat(error));
    }
  }, [matchId]);
  const isLive = matchState === SportinfoMatchState.Ongoing;
  const isNotStarted = matchState === SportinfoMatchState.NotStarted;
  const isFulltraff = productId && productId === productIds.FULLTRAFF;
  const oddsLable = odds => odds || '-';
  return React.createElement("div", {
    className: "sportinfo-bigstat-banner ".concat(className ? className : '')
  }, isLive && React.createElement(LiveDataSubscriber, {
    onUpdate: handleSocketUpdate,
    path: "/sport/1/sportevent/".concat(matchId)
  }), onCloseClick && React.createElement(IconButton, {
    className: "close-btn",
    onClick: onCloseClick
  }, React.createElement(Icon, {
    color: "fc-navy",
    icon: "close",
    size: "200"
  })), leagueName && React.createElement("div", {
    className: "sport-name f-content p f-capitalize"
  }, "".concat(SportTypeToString[sportType], ", ").concat(leagueName)), React.createElement(MatchHeader, {
    awayTeam: awayTeam,
    eventNumber: eventNumber,
    homeTeam: homeTeam,
    matchState: matchState,
    results: isLive ? liveResults : results
  }), odds && isNotStarted && !isFulltraff && React.createElement("div", {
    className: "f-content p odds"
  }, "1: ".concat(oddsLable(odds.one), " X: ").concat(oddsLable(odds.x), " 2: ").concat(oddsLable(odds.two))), React.createElement(MatchProgress, {
    matchState: matchState,
    sportEventStatus: isLive ? liveSportEventStatus : sportEventStatus,
    sportType: sportType,
    startTime: startTime,
    statusTime: isLive ? liveStatusTime : statusTime
  }));
};
setGlobal('svs.components.sportInfo.bigStatBanner.BigStatBanner', BigStatBanner);

 })(window);