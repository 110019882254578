(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sportinfo/bigstat-banner/assets/javascripts/bigstat-banner-skeleton.js') >= 0) return;  svs.modules.push('/components/sportinfo/bigstat-banner/assets/javascripts/bigstat-banner-skeleton.js');
"use strict";


const {
  Skeleton
} = svs.components.lbUi;
const BigstatBannerSkeleton = () => React.createElement("div", {
  className: "sportinfo-bigstat-banner skeleton-holder"
}, React.createElement(Skeleton, {
  className: "skeleton-line",
  height: 0
}), React.createElement(Skeleton, {
  className: "skeleton-line-big"
}), React.createElement(Skeleton, {
  className: "skeleton-line",
  height: 0
}));
setGlobal('svs.components.sportInfo.bigStatBanner.BigstatBannerSkeleton', BigstatBannerSkeleton);

 })(window);