(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sportinfo/bigstat-banner/assets/javascripts/match-progress.js') >= 0) return;  svs.modules.push('/components/sportinfo/bigstat-banner/assets/javascripts/match-progress.js');
"use strict";


const {
  useEffect,
  useState
} = React;
const {
  SportinfoMatchState,
  getSportInfoMatchState
} = svs.components.sportinfo.common.matchState;
const {
  getStatusText,
  hasMatchStartSurpassed
} = svs.components.sportinfo.sportConfig.helpers;
const barClassName = {
  [SportinfoMatchState.NotStarted]: 'not-started',
  [SportinfoMatchState.Ongoing]: 'ongoing',
  [SportinfoMatchState.Finished]: 'finished'
};
const StatusText = _ref => {
  let {
    progressText
  } = _ref;
  if (!progressText) {
    return null;
  }
  if (progressText.formattedTime && progressText.formattedDate) {
    return React.createElement("div", {
      className: "sportinfo_bigstat_header__matchstart"
    }, React.createElement("span", {
      className: "sportinfo_bigstat_header__matchstart_date"
    }, progressText.formattedDate), React.createElement("span", {
      className: "sportinfo_bigstat_header__matchstart_time"
    }, progressText.formattedTime));
  }
  return React.createElement("div", {
    className: "sportinfo_bigstat_header__matchstatus"
  }, progressText.matchStatusText);
};
const MatchProgress = _ref2 => {
  let {
    matchState,
    sportType,
    startTime,
    sportEventStatus,
    statusTime
  } = _ref2;
  const [, setUpdated] = useState(null);
  const progressText = getStatusText({
    sportEventStatus,
    sportType,
    statusTime,
    matchState,
    matchStart: startTime
  });
  const isNotStarted = matchState === SportinfoMatchState.NotStarted;
  const hasSurpassed = isNotStarted && hasMatchStartSurpassed(sportType, startTime, sportEventStatus);
  useEffect(() => {
    const interval = setInterval(() => {
      setUpdated(Date.now());
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);
  return React.createElement(React.Fragment, null, React.createElement("div", {
    className: "sportinfo_bigstat_header__progress_info"
  }, React.createElement(StatusText, {
    progressText: progressText
  })), React.createElement("div", {
    className: "color-bar ".concat(barClassName[hasSurpassed ? SportinfoMatchState.Finished : getSportInfoMatchState(sportEventStatus)])
  }));
};
setGlobal('svs.components.sportInfo.bigStatBanner.MatchProgress', MatchProgress);

 })(window);