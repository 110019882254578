(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sportinfo/bigstat-banner/assets/javascripts/bannerprops-handler.js') >= 0) return;  svs.modules.push('/components/sportinfo/bigstat-banner/assets/javascripts/bannerprops-handler.js');
"use strict";


const {
  useState,
  useEffect
} = React;
const logger = svs.core.log.getLogger('sport:sportinfo-bigstat');
const {
  OutcomeToAlphabeticStrings,
  EventTypes
} = svs.components.sportinfo.common.constants;
const {
  ResultTypes,
  helpers
} = svs.components.sport.sportEventCommon;
const BannerpropsHandler = _ref => {
  let {
    matchId,
    children
  } = _ref;
  const [isLoading, setIsLoading] = useState(true);
  const [bannerprops, setBannerprops] = useState({});
  useEffect(() => {
    let isActive = true;
    setIsLoading(true);
    const urls = ["/sport/1/sportevent/".concat(matchId), "/sport/1/sportevent/".concat(matchId, "/odds")];
    svs.core.jupiter.get("/multifetch?urls=".concat(urls.join('|')), response => {
      const [sportEventResponse, oddsResponse] = response.responses;
      const {
        sportEvent
      } = sportEventResponse;
      if (sportEvent) {
        const {
          participants,
          league,
          sport,
          startTime,
          results,
          status: sportEventStatus,
          statusTime
        } = sportEvent;
        let odds = null;
        if (oddsResponse) {
          const oddsType = oddsResponse.eventTypes.find(et => et.eventType === EventTypes.EvenType1X2);
          const oddsToParse = oddsType !== null && oddsType !== void 0 && oddsType.odds.length ? oddsType === null || oddsType === void 0 ? void 0 : oddsType.odds : oddsType === null || oddsType === void 0 ? void 0 : oddsType.startOdds;
          if (oddsToParse !== null && oddsToParse !== void 0 && oddsToParse.length) {
            odds = oddsToParse.reduce((acc, curr) => {
              acc[OutcomeToAlphabeticStrings[curr.outcome]] = curr.value;
              return acc;
            }, {});
          }
        }
        if (isActive) {
          setBannerprops({
            homeTeam: participants[0],
            awayTeam: participants[1],
            leagueName: league.name,
            sportType: sport.sportType,
            startTime,
            results: helpers.getCurrentResult(results) || results.find(result => result.type === ResultTypes.Fulltime),
            odds,
            sportEventStatus,
            statusTime
          });
        }
      }
      if (isActive) {
        setIsLoading(false);
      }
    }, error => {
      logger.warn("Failed to fetch match with id ".concat(matchId, ": ").concat(error));
      if (isActive) {
        setIsLoading(false);
      }
    });

    return () => {
      isActive = false;
    };
  }, [matchId]);
  return children(bannerprops, isLoading);
};
BannerpropsHandler.propTypes = {
  children: PropTypes.func.isRequired,
  matchId: PropTypes.number.isRequired
};
setGlobal('svs.components.sportInfo.bigStatBanner.BannerpropsHandler', BannerpropsHandler);

 })(window);