(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sportinfo/bigstat-banner/assets/javascripts/match-header.js') >= 0) return;  svs.modules.push('/components/sportinfo/bigstat-banner/assets/javascripts/match-header.js');
"use strict";

const {
  SportinfoMatchState
} = svs.components.sportinfo.common.matchState;
const {
  useMediaQuery,
  breakpoints,
  BREAKPOINT_LG,
  BREAKPOINT_MD
} = svs.components.lbUtils.useMediaQuery;
const {
  SmartAbbr,
  constants: {
    DescriptionSize,
    getTeamName
  }
} = svs.components.lbUi.participants;
const trimTeamName = (teamName, maxLength) => {
  if (!teamName || teamName.length <= maxLength) {
    return teamName;
  }
  return "".concat(teamName.substr(0, maxLength - 1).trim(), ".");
};
const getTeamNameAndTitle = (participant, isLargeDevice) => {
  const nameSize = isLargeDevice ? DescriptionSize.Medium : DescriptionSize.Full;
  const name = getTeamName(participant, nameSize);
  const trimmedName = isLargeDevice ? trimTeamName(name, 15) : name;
  const title = (participant === null || participant === void 0 ? void 0 : participant.name) || name;
  return [trimmedName, title];
};
const MatchHeader = _ref => {
  let {
    matchState,
    homeTeam,
    eventNumber,
    awayTeam,
    results
  } = _ref;
  const isMediumDevice = useMediaQuery(breakpoints.down(BREAKPOINT_MD));
  const isLargeDevice = useMediaQuery(breakpoints.down(BREAKPOINT_LG));
  const [homeTeamName, homeTeamTitle] = getTeamNameAndTitle(homeTeam, isLargeDevice);
  const [awayTeamName, awayTeamTitle] = getTeamNameAndTitle(awayTeam, isLargeDevice);
  const teamNameClass = "sportinfo_bigstat_header__participants f-content h".concat(isMediumDevice ? 3 : 1);
  const scoreClassname = "f-content ".concat(isMediumDevice ? 'f-400 f-medium' : 'h2');
  if (matchState === SportinfoMatchState.NotStarted || !results) {
    return React.createElement("div", {
      className: "sportinfo_bigstat_header__participants--with_event_number ".concat(teamNameClass)
    }, React.createElement("span", {
      className: "sportinfo_bigstat_header__event_number"
    }, eventNumber && React.createElement(React.Fragment, null, React.createElement("span", {
      className: "sportinfo_bigstat_header__event_number_text"
    }, eventNumber), React.createElement("span", {
      className: "sportinfo_bigstat_header__event_number_dot"
    }, "."))), React.createElement("span", {
      className: "sportinfo_bigstat_header__participants_name sportinfo_bigstat_header__participants_name--home home-participant"
    }, React.createElement(SmartAbbr, {
      title: homeTeamTitle
    }, homeTeamName)), React.createElement("span", {
      className: "participant-split"
    }, " - "), React.createElement("span", {
      className: "sportinfo_bigstat_header__participants_name sportinfo_bigstat_header__participants_name--away away-participant"
    }, React.createElement(SmartAbbr, {
      title: awayTeamTitle
    }, awayTeamName)));
  }
  return React.createElement("div", {
    className: "match-header-holder sportinfo_bigstat_header__participants sportinfo_bigstat_header__participants--with_score_board"
  }, React.createElement("div", {
    className: "sportinfo_bigstat_header__participants_name sportinfo_bigstat_header__participants_name--home team-name-left ".concat(teamNameClass)
  }, React.createElement(SmartAbbr, {
    title: homeTeamTitle
  }, homeTeamName)), React.createElement("div", {
    className: "score-holder"
  }, React.createElement("div", {
    className: "score-number ".concat(scoreClassname)
  }, results.home), React.createElement("div", {
    className: "score-number ".concat(scoreClassname)
  }, results.away)), React.createElement("div", {
    className: "sportinfo_bigstat_header__participants_name sportinfo_bigstat_header__participants_name--away team-name-right ".concat(teamNameClass)
  }, React.createElement(SmartAbbr, {
    title: awayTeamTitle
  }, awayTeamName)));
};
setGlobal('svs.components.sportInfo.bigStatBanner.MatchHeader', MatchHeader);

 })(window);